
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class LayerWaterFootprint extends Vue {
    
    @Prop() productionPhase: string;
    @Prop() currentWaterFootprints: OM.WaterFootprintPerMaterial[];

    get filteredCurrentWaterFootprints(){
        if(!this.currentWaterFootprints)
            return [];

        return this.currentWaterFootprints.filter(x => {
            return x.productionPhase.text == this.productionPhase
        })
    }

}
